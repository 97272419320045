<template>
  <div
    class="tooltip-container rounded-8 brand-black-bg brand-white index-1 smooth-animation"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "tooltip",

  props: {
    text: {
      type: String,
      default: "Tooltip text",
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-container {
  padding: toRem(11.5) toRem(13.5);
  transform: translateX(-25%);
  white-space: nowrap;
  position: absolute;
  text-align: center;
  top: toRem(52);
  width: auto;
  left: -25%;

  &::after {
    content: "";
    position: absolute;
    top: toRem(-4);
    left: 48%;
    transform: rotate(45deg);
    @include square-shape(8);
    background: $brand-black;
  }
}
</style>
