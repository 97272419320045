<template>
  <div
    class="nav-build fixed-top w-100 index-999"
    :class="!isBasePage ? 'brand-sky-blue-bg' : 'brand-white-bg'"
  >
    <div class="container px-3 px-md-1">
      <div class="nav-content w-100 mx-auto">
        <!-- APP LOGO  -->
        <router-link to="/" class="app-logo">
          <img v-lazy="staticAsset('Class54Logo.svg')" alt="class54" />
        </router-link>

        <!-- NAV ITEMS -->
        <div class="nav-items smooth-transition">
          <!-- ABOUT LINK  -->
          <router-link
            to="/about"
            class="item"
            :class="currentRoute === 'About' ? 'active-link' : null"
          >
            <div class="text">About</div>
          </router-link>

          <!-- PRICING LINK  -->
          <router-link
            to="/pricing"
            class="item"
            :class="currentRoute === 'Pricing' ? 'active-link' : null"
          >
            <div class="text">Pricing</div>
          </router-link>

          <!-- DOWNLOADS LINK  -->
          <router-link
            to=""
            class="item nav-toggler"
            @mouseenter.native="show_dropdown = true"
            @mouseleave.native="show_dropdown = false"
          >
            <div class="text">Downloads</div>
            <div class="icon icon-caret-down"></div>

            <!-- NAV DROPDOWN  -->
            <div
              class="
                nav-dropdown
                rounded-4
                brand-white-bg
                index-1
                smooth-animation
              "
              v-if="show_dropdown"
            >
              <div class="inner-wrapper position-relative w-100 h-auto">
                <div class="connector"></div>

                <!-- ANDROID  -->
                <router-link
                  to
                  @click.native="
                    openMobileApp(
                      'https://play.google.com/store/apps/details?id=com.class54.mobile'
                    )
                  "
                  class="nav-item index-1"
                >
                  <div class="avatar">
                    <div class="icon icon-android"></div>
                  </div>

                  <div>
                    <div class="title">Android</div>
                    <div class="value">
                      Lightweight and fast for all your prep needs
                    </div>
                  </div>
                </router-link>

                <!-- IOS  -->
                <router-link
                  to
                  @click.native="
                    openMobileApp(
                      'https://apps.apple.com/us/app/class54/id1622971742'
                    )
                  "
                  class="nav-item"
                >
                  <div class="avatar">
                    <div class="icon icon-apple"></div>
                  </div>

                  <div>
                    <div class="title">IOS</div>
                    <div class="value">
                      Lightweight and fast for all your prep needs
                    </div>
                  </div>
                </router-link>

                <!-- DESKTOP  -->
                <router-link to="/jamb-software-download" class="nav-item">
                  <div class="avatar">
                    <div class="icon icon-windows-angle"></div>
                  </div>

                  <div>
                    <div class="title">Desktop (windows)</div>
                    <div class="value">
                      Exam scenario offline on your computer
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </router-link>

          <!-- BLOG LINK -->
          <a href="https://medium.com/@class54edu" target="_blank" class="item">
            <div class="text">Blog</div>
          </a>
        </div>

        <!-- NAV OPTIONS -->
        <div class="nav-options smooth-transition">
          <!-- SOCIAL LINKS -->
          <div class="social-links">
            <a href="tel:+2348167042330" class="social-circle avatar">
              <div class="icon icon-phone"></div>

              <div class="tip">
                <tooltip text="Call 08167042330" />
              </div>
            </a>

            <a
              href="https://api.whatsapp.com/send?phone=2348167042330&text=%F0%9F%A4%9D"
              class="social-circle avatar"
            >
              <div class="icon icon-whatsapp"></div>

              <div class="tip">
                <tooltip text="Whatsapp chat" />
              </div>
            </a>
          </div>

          <router-link
            to="/activate-desktop"
            class="btn btn-primary activate-link"
            >Activate Desktop</router-link
          >

          <!-- LOGIN BUTTON  -->
          <router-link
            to
            @click.native="processAuth"
            class="btn btn-outline"
            v-if="false"
            >{{ getAuthToken ? "Log Out" : "Login" }}</router-link
          >
        </div>

        <!-- MOBILE SECTION -->
        <div class="d-flex d-md-none justify-content-end align-items-center">
          <!-- SOCIAL LINKS -->
          <div class="social-links d-md-none">
            <a href="tel:+2348167042330" class="social-circle avatar">
              <div class="icon icon-phone"></div>

              <div class="tip">
                <tooltip text="Call 08167042330" />
              </div>
            </a>

            <a
              href="https://api.whatsapp.com/send?phone=2348167042330&text=%F0%9F%A4%9D"
              class="social-circle avatar"
            >
              <div class="icon icon-whatsapp"></div>

              <div class="tip">
                <tooltip text="Whatsapp chat" />
              </div>
            </a>
          </div>

          <!-- MENU TOGGLE -->
          <div class="menu-toggler smooth-transition" @click="toggleMobileMenu">
            <div
              class="
                icon icon-hamburger
                brand-dark-blue
                smooth-transition
                pointer
              "
              title="Menu"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- MENU  -->
    <mobile-menu v-if="show_mobile_menu" @closeMenu="toggleMobileMenu" />

    <portal to="54-modals">
      <transition name="fade" v-if="show_mobile_download">
        <mobile-download-modal @closeTriggered="toggleMobileDownload" />
      </transition>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import mobileMenu from "@/shared/components/mobile-menu";
import tooltip from "@/shared/components/tooltip";

export default {
  name: "navBuild",

  components: {
    mobileMenu,
    tooltip,
    mobileDownloadModal: () =>
      import(
        /* webpackChunkName */ "@/modules/landing/modals/mobile-download-modal"
      ),
  },

  props: {
    isBasePage: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    ...mapGetters({
      getAuthToken: "auth/getAuthToken",
      seenMobileDownload: "general/seenMobileDownload",
    }),

    currentRoute() {
      return this.$route.name;
    },
  },

  watch: {
    $route: {
      handler() {
        if (this.show_mobile_menu) this.toggleMobileMenu();
      },
    },
  },

  mounted() {
    setTimeout(() => this.showMobileAppDownload(), 4000);
  },

  data: () => ({
    show_dropdown: false,
    show_mobile_menu: false,
    show_mobile_download: false,
  }),

  methods: {
    ...mapActions({
      logoutUser: "auth/logoutUser",
      updateMobileDownloadState: "general/updateMobileDownloadState",
    }),

    showMobileAppDownload() {
      // CHECK IF USE HAS SEEN MOBILE DOWNLOAD
      if (!this.seenMobileDownload) {
        if (window.matchMedia("(max-width: 568px)").matches)
          this.show_mobile_download = true;
      }
    },

    toggleMobileMenu() {
      this.show_mobile_menu = !this.show_mobile_menu;
    },

    toggleMobileDownload() {
      this.updateMobileDownloadState();
      this.show_mobile_download = !this.show_mobile_download;
    },

    openMobileApp(url) {
      let link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      link.click();
    },

    logOut() {
      this.$bus.$emit("show-page-loader", "Logging out");

      this.logoutUser()
        .then((response) => {
          if (response.data.status_code == 200) {
            setTimeout(() => {
              location.href = "/select-subscription";
            }, 2000);
          }
        })
        .catch(() => {
          localStorage.clear();
          location.href = "/select-subscription";
        });
    },

    processAuth() {
      if (this.getAuthToken) this.logOut();
      else this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-build {
  padding: toRem(16) 0;
  @include transition(0.3s);

  .nav-content {
    @include flex-row-between-nowrap;

    .app-logo {
      img {
        @include rectangle-shape(160, 35);
        @include breakpoint-down(xl) {
          position: relative;
          left: toRem(-6);
        }
        @include breakpoint-down(md) {
          @include rectangle-shape(150, 32);
        }
      }
    }

    .nav-items {
      @include flex-row-center-nowrap;

      @include breakpoint-down(md) {
        display: none;
      }

      .item {
        padding: toRem(7) toRem(16);
        border-radius: toRem(20);

        @include breakpoint-custom-down(850) {
          padding: toRem(7) toRem(12);
          font-size: toRem(13.75);
        }

        &:hover {
          color: $blackish-grey;
          background: #ddf4e9;
        }
      }

      .active-link {
        color: $blackish-grey;
        background: #ddf4e9;
      }

      .item {
        @include flex-row-center-nowrap;
        margin: 0 toRem(9);
        position: relative;

        @include breakpoint-down(lg) {
          margin: 0 toRem(3);
        }

        .icon {
          @include transition(0.5s);
          margin-right: toRem(-2);
          margin-left: toRem(5);
          font-size: toRem(14);
        }

        &:hover {
          .icon {
            transform: rotate(180deg);
          }
        }

        .nav-dropdown {
          position: absolute;
          padding: toRem(9) toRem(8);
          top: toRem(42);
          height: auto;
          left: 0;
          width: toRem(305);
          box-shadow: 0 toRem(18) toRem(80) rgba($brand-black, 0.04),
            0 toRem(11.6667) toRem(46.8519) rgba($brand-black, 0.0303704),
            0 toRem(6.93333) toRem(25.4815) rgba($brand-black, 0.0242963),
            0 toRem(3.6) toRem(13) rgba($brand-black, 0.02),
            0 toRem(1.46667) toRem(6.51852) rgba($brand-black, 0.0157037),
            0 toRem(0.333333) toRem(3.14815) rgba($brand-black, 0.00962963);

          .connector {
            position: absolute;
            border: toRem(1) solid transparent;
            left: 0;
            top: toRem(-24);
            height: toRem(20);
            width: toRem(110);
          }

          .nav-item {
            @include flex-row-start-nowrap;
            align-items: flex-start;
            padding: toRem(8) toRem(14);
            border-radius: toRem(4);
            @include transition(0.4s);

            .avatar {
              background: $brand-sky-blue;
              @include square-shape(28);
              margin-right: toRem(10);

              .icon {
                @include center-y;
                font-size: toRem(16.5);
                color: $brand-dark-green;
              }
            }

            .title {
              @include font-height(13.25, 19);
              color: $blackish-grey;
              margin-bottom: toRem(4);
              font-weight: 700;
            }

            .value {
              @include font-height(11.45, 16);
              color: $blackish-grey;
              font-weight: normal;
            }

            &:hover {
              background: #f2f2f2;
              .title {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .nav-options {
      @include flex-row-end-nowrap;

      @include breakpoint-down(md) {
        display: none;
      }

      .activate-link {
        padding: toRem(14) toRem(30);
        // border-radius: toRem(20);

        @include breakpoint-custom-down(850) {
          // padding: toRem(7) toRem(12);
          font-size: toRem(13.75);
        }

        &:hover {
          color: $blackish-grey;
          background: #ddf4e9;
        }
      }

      .btn {
        margin-left: toRem(25);

        @include breakpoint-down(lg) {
          margin-left: toRem(20);
        }

        @include breakpoint-custom-down(850) {
          margin-left: toRem(8);
        }
      }
    }

    .social-links {
      @include flex-row-start-wrap;
      margin-left: toRem(-50);
      margin-right: toRem(20);

      @include breakpoint-down(lg) {
        margin-left: toRem(-30);
        margin-right: toRem(-5);
      }

      @include breakpoint-down(md) {
        margin-right: toRem(30);
      }

      @include breakpoint-down(xs) {
        margin-right: toRem(15);
      }

      .social-circle {
        @include square-shape(42);
        @include transition(0.4s);
        cursor: pointer;
        overflow: unset;

        @include breakpoint-down(lg) {
          @include square-shape(38);
        }

        @include breakpoint-down(md) {
          @include square-shape(45);
        }

        .tip {
          display: none;
        }

        &:hover {
          background: #ddf4e9;

          .tip {
            display: unset;
          }
        }

        .icon {
          @include center-placement;
          font-size: toRem(18);
          color: $brand-black;

          @include breakpoint-down(lg) {
            font-size: toRem(16);
          }

          @include breakpoint-down(md) {
            font-size: toRem(20);
          }
        }

        &:last-of-type {
          margin-left: toRem(10);

          @include breakpoint-down(lg) {
            margin-left: toRem(5);
          }
        }
      }
    }

    .menu-toggler {
      display: none;

      @include breakpoint-down(md) {
        display: unset;
      }

      .icon {
        font-size: toRem(30);
      }
    }
  }
}
</style>
