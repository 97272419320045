<template>
  <div class="footer-build brand-white-bg">
    <div class="container px-3 px-sm-3 px-md-4 px-xl-5">
      <div class="footer-content mx-auto">
        <!-- TOP  -->
        <div class="top">
          <!-- LEFT SECTION  -->
          <div class="left-section">
            <router-link :to="{ name: 'About' }" class="item"
              >About</router-link
            >
            <a href="/about#faq" class="item">FAQ</a>
            <a href="/about#contact" class="item">Contact</a>
            <a
              href="https://medium.com/@class54edu"
              target="_blank"
              class="item"
              >Blog</a
            >
            <router-link :to="{ name: 'PrivacyPolicy' }" class="item"
              >Privacy policy</router-link
            >
          </div>

          <!-- RIGHT SECTION  -->
          <div class="right-section">
            <a href="https://www.instagram.com/class54edu/" to class="item"
              >Instagram</a
            >
            <a href="https://www.facebook.com/class54edu" to class="item"
              >facebook</a
            >
            <a href="https://twitter.com/Class54Edu" class="item">twitter</a>

            <a href="tel:+2348167042330" class="item telephone">
              <span class="icon icon-phone"></span>
              <span>+2348167042330</span>
            </a>

            <a href="tel:+2349035409551" class="item telephone">
              <span class="icon icon-phone"></span>
              <span>+2349035409551</span>
            </a>
          </div>
        </div>

        <!-- BOTTOM -->
        <div class="bottom brand-dark-blue text-center">
          Copyright © {{ current_year }} Class54. All Rights Reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-build",

  data: () => ({
    current_year: "",
  }),

  created() {
    let today = new Date();
    this.current_year = today.getFullYear();
  },
};
</script>

<style lang="scss" scoped>
.footer-build {
  .footer-content {
    padding: toRem(64) 0 toRem(48);

    @include breakpoint-down(md) {
      padding: toRem(54) 0 toRem(38);
    }

    @include breakpoint-down(xs) {
      padding: toRem(35) 0 toRem(30);
    }

    .top {
      @include flex-row-between-wrap;
      margin-bottom: toRem(60);

      @include breakpoint-down(md) {
        margin-bottom: toRem(40);
      }

      .left-section {
        @include flex-row-start-wrap;

        @include breakpoint-down(md) {
          @include flex-row-center-wrap;
          margin-bottom: toRem(20);
          width: 100%;
        }

        @include breakpoint-down(xs) {
          @include flex-row-between-wrap;
        }

        .item {
          margin-right: toRem(32);

          @include breakpoint-down(xs) {
            margin-right: 0;
            font-size: toRem(13);
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .right-section {
        @include flex-row-end-wrap;

        @include breakpoint-down(md) {
          @include flex-row-center-wrap;
          width: 100%;
        }

        @include breakpoint-down(xs) {
          @include flex-row-around-wrap;
        }

        .item {
          margin-right: toRem(32);

          @include breakpoint-down(xs) {
            margin-right: 0;
            font-size: toRem(13);
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      .item {
        @include font-height(13.5, 18);
        color: $brand-dark-blue;

        &:hover {
          color: $brand-dark-green;
        }
      }

      .telephone {
        color: $brand-accent-green;

        .icon {
          position: relative;
          top: toRem(2);
          left: toRem(-2);
        }
      }
    }

    .bottom {
      @include font-height(13.5, 18);

      @include breakpoint-down(xs) {
        @include font-height(13, 17);
      }
    }
  }
}
</style>
